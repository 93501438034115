import React, { useContext } from 'react';
import { graphql, navigate } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { store } from '../store/store';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import FullScreenProject from '../components/FullScreenProject';
import { variants } from '../components/Header';
import ProjectLayout from '../layouts/ProjectLayout';
import SVG from 'react-inlinesvg';
import Arrow from '../../static/images/arrow.svg';

const Header = styled(motion.header)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.4rem 6rem;
  text-transform: uppercase;
  color: white;
  font-size: 2.2rem;
  font-family: ${({ theme }) => theme.fontFamily};

  a {
    border-bottom: 1px solid;
  }
`;

const PageTitle = styled.h1`
  font-family: ${({ theme }) => theme.fontFamilyHeading};
  font-size: 3.6rem;
  text-transform: uppercase;
`;

const BackButton = styled.button`
  appearance: none;
  background: transparent;
  padding: 0;
  margin: 0;
  box-shadow: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

const Projectpage = props => {
  console.log(props);
  const { state, dispatch } = useContext(store);
  const { title, subtitle, image } = props.data.prismicProjects.data;

  const handleClick = () => {
    navigate('/');
    dispatch({ type: 'SET_INTRO_IS_READY' });
  };

  return (
    <ProjectLayout>
      {/* if state.activeProject dan FullScreenProject, anders gatsby-image met image from GQL */}
      {state.activeProject ? (
        <FullScreenProject />
      ) : (
        <Img
          fluid={image.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          imgStyle={{ filter: 'grayscale(1)' }}
        />
      )}
      <Header variants={variants} initial="initial" animate="enter">
        <BackButton onClick={() => handleClick()}>
          <SVG src={Arrow} />
        </BackButton>
        <PageTitle>{title.text}</PageTitle>
        <p>(view website)</p>
      </Header>
    </ProjectLayout>
  );
};

export default Projectpage;

export const PROJECT_QUERY = graphql`
  query project($id: String!) {
    prismicProjects(id: { eq: $id }) {
      data {
        title {
          text
        }
        subtitle {
          text
        }
        image {
          fluid(maxWidth: 2560) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
  }
`;
