import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from '../styling/GlobalStyle';
import theme from '../styling/theme';

const Wrapper = styled.article`
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`;

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Wrapper>{children}</Wrapper>
    </ThemeProvider>
  );
};

export default Layout;
